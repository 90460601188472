import { all, takeEvery, put } from "redux-saga/effects";
import * as PinApi from "../../api/getPin";
import { GET_PIN_REQUEST } from "../constans/pin.constants";
import {
  GetPinAction,
  getPinError,
  getPinSuccess,
  showBanner,
} from "../actions/pin.action";
import { PinType } from "../../types/pin";
import axios from "axios";

function* getPinSaga(action: GetPinAction) {
  try {
    const pin: PinType = yield PinApi.getPin(action.payload);
    yield (axios.defaults.headers.common["Accept"] = "application/json");
    yield (axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${pin.accessToken}`);
    yield put(getPinSuccess(pin));
    yield put(showBanner(true));
  } catch (error: any) {
    put(getPinError(error));
  }
}

export default function* rootSaga() {
  yield all<PinType>([yield takeEvery(GET_PIN_REQUEST, getPinSaga)]);
}
