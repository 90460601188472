import { all, takeEvery, put } from "redux-saga/effects";
import { signOutError, signOutSuccess } from "../actions/signOut.action";
import { PinType } from "../../types/pin";
import axios from "axios";
import { SIGN_OUT_REQUEST } from "../constans/signout.constants";

function* signOutSaga() {
  try {
    yield (axios.defaults.headers.common["Accept"] = "application/json");
    yield (axios.defaults.headers.common["Authorization"] = "");
    yield put(signOutSuccess());
  } catch (error: any) {
    put(signOutError(error));
  }
}

export default function* rootSaga() {
  yield all<PinType>([yield takeEvery(SIGN_OUT_REQUEST, signOutSaga)]);
}
