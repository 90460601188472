import { ManifestAction } from "../actions/manifest.action";
import {
  UPDATE_MANIFEST_REQUEST,
  UPDATE_MANIFEST_SUCCESS,
} from "../constans/manifest.constant";

type ManifestState = {
  loading: boolean;
  updated: boolean;
};

const initialState: ManifestState = {
  loading: false,
  updated: false,
};

const ManifestReducer = (
  state = initialState,
  action: ManifestAction
): ManifestState => {
  switch (action.type) {
    case UPDATE_MANIFEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MANIFEST_SUCCESS:
      return {
        ...state,
        updated: true,
        loading: false,
      };

    default:
      return state;
  }
};

export default ManifestReducer;
