import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { RoutesApp } from "./routes/routes";
import "./i18n/config";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RoutesApp />
      </PersistGate>
    </Provider>
  );
};

export default App;
