import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useNavigate } from "react-router";
import { getIframeData } from "../store/actions/iframe.actions";
import MobileDetect from "mobile-detect";
import { getPinSuccess, showBanner } from "../store/actions/pin.action";
import DownloadBanner from "../components/downloadPopup/downloaderPopup";
import { useSearchParams } from "react-router-dom";
import { decodeToken } from "../uitils/helpers/decodeToken";
import { signOut } from "../store/actions/signOut.action";

const Home = () => {
  const AES_KEY = "t6w9z$C&F)J@NcRfUjWnZr4u7x!A%D*G";
  const type = new MobileDetect(window.navigator.userAgent);
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isIphone = type.os() === "iOS";
  let deferredPrompt: any;
  const MINUTE = 60000;
  const { iframe, expire, token, allowDownload } = useSelector(
    ({ iframe, pin, bannerValue }: RootState) => ({
      iframe: iframe.iframe?.url,
      expire: pin.pin?.token.expires_at,
      token: pin.pin?.accessToken,
      allowDownload: bannerValue.showBanner,
    })
  );
  const [crypted, setCrypted] = useState<string | null>(null);
  const [pin] = useSearchParams();
  const pinData = pin.get("access");
  const expireData = pin.get("expire");
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const CryptoJS = require("crypto-js");

  useEffect(() => {
    addToHome();
    if (isIphone) {
      setShow(true);
    }
  }, [isIphone]);

  useEffect(() => {
    if (token) {
      console.log(new Date(decodeToken(token).exp * 1000));

      dispatch(getIframeData());
      const encrypted = CryptoJS.AES.encrypt(token, AES_KEY).toString();
      setCrypted(encrypted);
    }
  }, [token]);

  useEffect(() => {
    if (token && expire) {
      const interval = setInterval(() => {
        if (new Date(expire) <= new Date()) {
          dispatch(signOut());
          setTimeout(() => {
            navigate("/noAccess");
          }, 1000);
          clearInterval(interval);
        }
      }, 5 * MINUTE);
    }
  }, [token, expire]);

  const addToHome = async () => {
    await window.addEventListener("beforeinstallprompt", (e) => {
      setShow(true);
      e.preventDefault();
      deferredPrompt = e;
      const addBtn = document.getElementById("installBtn");
      addBtn?.addEventListener("click", () => {
        setTimeout(() => {
          setShow(false);
        }, 500);
        if (deferredPrompt !== null) {
          deferredPrompt.prompt();
          const { outcome } = deferredPrompt.userChoice;
          if (outcome === "accepted") {
            deferredPrompt = null;
          } else {
            dispatch(showBanner(false));
          }
        }
      });
    });
  };

  useEffect(() => {
    if (token && isIphone) {
      navigate({
        pathname: "/",
        search: `?access=${crypted}`,
      });
    } else if (token && !isIphone) {
      navigate("/", { replace: true });
    }
  }, [crypted]);

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches && isIphone) {
      const pin = pinData?.replaceAll(" ", "+");
      const tokenDecrypted = CryptoJS.AES.decrypt(pin, AES_KEY).toString(
        CryptoJS.enc.Utf8
      );

      const expData = new Date(decodeToken(tokenDecrypted).exp * 1000);
      dispatch(
        getPinSuccess({
          accessToken: tokenDecrypted,
          token: {
            id: "0",
            user_id: 0,
            client_id: 0,
            revoked: false,
            expires_at: expData.toString(),
          },
        })
      );
    }
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      {allowDownload && show && <DownloadBanner isIphone={isIphone} />}
      <iframe
        frameBorder={0}
        src={iframe}
        style={{
          width: "100%",
          height: "100vh",
          overflowX: "hidden",
        }}
        title={"iframe"}
      />
    </div>
  );
};

export default Home;
