import {
  SIGN_OUT_ERROR,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
} from "../constans/signout.constants";

export interface SignOutAction {
  type: typeof SIGN_OUT_REQUEST;
}

interface SignOutActionSuccess {
  type: typeof SIGN_OUT_SUCCESS;
}

interface SignOutErrorAction {
  type: typeof SIGN_OUT_ERROR;
  payload: Error;
  error: boolean;
}

export const signOut = (): SignOutAction => ({
  type: SIGN_OUT_REQUEST,
});

export const signOutSuccess = (): SignOutActionSuccess => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutError = (error: Error): SignOutErrorAction => ({
  type: SIGN_OUT_ERROR,
  payload: error,
  error: true,
});

export type AuthAction =
  | SignOutErrorAction
  | SignOutActionSuccess
  | SignOutAction;
