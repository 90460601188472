import {
  GET_PIN_ERROR,
  GET_PIN_REQUEST,
  GET_PIN_SUCCESS,
  SHOW_BANNER_VALUE,
} from "../constans/pin.constants";
import { PinType } from "../../types/pin";

export interface ShowBannerAction {
  type: typeof SHOW_BANNER_VALUE;
  payload: boolean;
}

export interface GetPinAction {
  type: typeof GET_PIN_REQUEST;
  payload: string;
}

interface GetPinSuccessAction {
  type: typeof GET_PIN_SUCCESS;
  payload: PinType;
}

interface GetPinErrorAction {
  type: typeof GET_PIN_ERROR;
  payload: Error;
  error: boolean;
}

export const getPinData = (payload: string): GetPinAction => ({
  type: GET_PIN_REQUEST,
  payload: payload,
});

export const getPinSuccess = (payload: PinType): GetPinSuccessAction => ({
  type: GET_PIN_SUCCESS,
  payload: payload,
});

export const getPinError = (error: Error): GetPinErrorAction => ({
  type: GET_PIN_ERROR,
  payload: error,
  error: true,
});

export const showBanner = (payload: boolean): ShowBannerAction => ({
  type: SHOW_BANNER_VALUE,
  payload: payload,
});

export type PinAction =
  | GetPinAction
  | GetPinSuccessAction
  | GetPinErrorAction
  | ShowBannerAction;
