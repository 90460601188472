import { PinType } from "../../types/pin";
import { PinAction } from "../actions/pin.action";
import {
  GET_PIN_ERROR,
  GET_PIN_REQUEST,
  GET_PIN_SUCCESS,
} from "../constans/pin.constants";
import { AuthAction } from "../actions/signOut.action";
import { SIGN_OUT_REQUEST } from "../constans/signout.constants";

type PinState = {
  pin: PinType | null;
  loading: boolean;
  showBanner: boolean;
  error: Error | null;
};

const initialState: PinState = {
  pin: null,
  loading: false,
  showBanner: false,
  error: null,
};

const PinReducer = (
  state = initialState,
  action: PinAction | AuthAction
): PinState => {
  switch (action.type) {
    case GET_PIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PIN_SUCCESS:
      return {
        ...state,
        pin: action.payload,
        loading: false,
      };

    case GET_PIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SIGN_OUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "SIGN_OUT_SUCCESS":
      return {
        ...state,
        loading: false,
        pin: null,
      };
    case "SIGN_OUT_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PinReducer;
