import axios, { AxiosResponse } from "axios";
import { apiIframeMapper } from "./apiMapper/iFrameMapper";

const BASE_AUTH_URL = "/iframe";

export const getIframe = (): Promise<{ url: string; name: string }> => {
  return axios.get(BASE_AUTH_URL).then((resp: AxiosResponse) => {
    return apiIframeMapper(resp.data.data);
  });
};
