export const apiIframeMapper = (apiProfileCustomer: {
  url: string;
  name: string;
}): { url: string; name: string } => {
  const { url, name } = apiProfileCustomer;
  return {
    url,
    name,
  };
};
