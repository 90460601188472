let baseUrl = process.env.REACT_APP_API_URL; // Staging will be default for now

if (process.env.environment === "dev") {
  baseUrl = ""; // TODO dev url
}

if (process.env.environment === "local") {
  baseUrl = ""; // TODO local url
}

if (process.env.environment === "prod") {
  baseUrl = ""; // TODO add prod url
}

export { baseUrl };
