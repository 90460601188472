import { all, takeEvery, put, select } from "redux-saga/effects";
import * as IframeApi from "../../api/getIframe_api";
import { getPinError } from "../actions/pin.action";
import { getIframeSuccess } from "../actions/iframe.actions";
import { GET_IFRAME_REQUEST } from "../constans/iframe.constants";
import axios from "axios";
import { RootState } from "../reducers";
import { PinType } from "../../types/pin";
import { updateManifestData } from "../actions/manifest.action";

function* getIframeSaga() {
  try {
    const pinState = (state: RootState) => state.pin.pin;
    const selectAuthState: PinType | null = yield select(pinState);
    if (selectAuthState != null) {
      yield (axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${selectAuthState.accessToken.toString()}`);
    }

    const iframe: { url: string; name: string } = yield IframeApi.getIframe();

    yield put(getIframeSuccess(iframe));
    yield put(updateManifestData(iframe));
  } catch (error: any) {
    put(getPinError(error));
  }
}

export default function* rootSaga() {
  yield all<{ url: string }>([
    yield takeEvery(GET_IFRAME_REQUEST, getIframeSaga),
  ]);
}
