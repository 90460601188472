import { PinAction } from "../actions/pin.action";

type BannerState = {
  showBanner: boolean;
};

const initialState: BannerState = {
  showBanner: false,
};

const PinReducer = (state = initialState, action: PinAction): BannerState => {
  switch (action.type) {
    case "SHOW_BANNER_VALUE":
      return {
        ...state,
        showBanner: action.payload,
      };

    default:
      return state;
  }
};

export default PinReducer;
