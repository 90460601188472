import { PinType } from "../types/pin";
import axios, { AxiosResponse } from "axios";
import { apiPinMapper } from "./apiMapper/pinMapper";

const BASE_AUTH_URL = "auth/pin";

export const getPin = (pin: string): Promise<PinType> => {
  return axios
    .post(BASE_AUTH_URL, {
      pin: pin,
    })
    .then((resp: AxiosResponse) => {
      return apiPinMapper(resp.data);
    });
};
