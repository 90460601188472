import { applyMiddleware, createStore } from "redux";

import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";
import storage from "redux-persist/lib/storage";
import saga from "./saga";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["pin", "bannerValue"],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({});
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);
sagaMiddleware.run(saga);
export { store, persistor };
