import { all, takeEvery, put, select } from "redux-saga/effects";
import { getPinError } from "../actions/pin.action";
import { manifestConfiguration } from "../../configurations/manifest.config";
import { updateManifestSuccess } from "../actions/manifest.action";
import { UPDATE_MANIFEST_REQUEST } from "../constans/manifest.constant";
import { RootState } from "../reducers";

function* updateManifestSaga() {
  try {
    const iframeState = (state: RootState) => state.iframe.iframe;
    const selectIframeState: {
      url: string;
      name: string;
    } | null = yield select(iframeState);

    if (selectIframeState != null) {
      const url = manifestConfiguration({
        name: selectIframeState?.name,
        shortName: selectIframeState?.name,
      });
      document.getElementById("manifest")?.setAttribute("href", url);
      yield put(updateManifestSuccess());
    }
  } catch (error: any) {
    put(getPinError(error));
  }
}

export default function* rootSaga() {
  yield all([
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield takeEvery(UPDATE_MANIFEST_REQUEST, updateManifestSaga),
  ]);
}
