import { PinType } from "../../types/pin";

export const apiPinMapper = (apiProfileCustomer: PinType): PinType => {
  const { accessToken, token } = apiProfileCustomer;

  return {
    accessToken,
    token,
  };
};
