import {
  GET_IFRAME_ERROR,
  GET_IFRAME_REQUEST,
  GET_IFRAME_SUCCESS,
} from "../constans/iframe.constants";

export interface GetIframeAction {
  type: typeof GET_IFRAME_REQUEST;
}

interface GetIframeSuccessAction {
  type: typeof GET_IFRAME_SUCCESS;
  payload: { url: string; name: string };
}

interface GetIframeErrorAction {
  type: typeof GET_IFRAME_ERROR;
  payload: Error;
  error: boolean;
}

export const getIframeData = (): GetIframeAction => ({
  type: GET_IFRAME_REQUEST,
});

export const getIframeSuccess = (payload: {
  url: string;
  name: string;
}): GetIframeSuccessAction => ({
  type: GET_IFRAME_SUCCESS,
  payload: payload,
});

export const getIframeError = (error: Error): GetIframeErrorAction => ({
  type: GET_IFRAME_ERROR,
  payload: error,
  error: true,
});

export type IframeAction =
  | GetIframeAction
  | GetIframeSuccessAction
  | GetIframeErrorAction;
