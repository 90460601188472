import {
  UPDATE_MANIFEST_REQUEST,
  UPDATE_MANIFEST_SUCCESS,
} from "../constans/manifest.constant";

export interface UpdateManifestRequest {
  type: typeof UPDATE_MANIFEST_REQUEST;
  payload: { url: string; name: string };
}

interface UpdateManifestActionSuccess {
  type: typeof UPDATE_MANIFEST_SUCCESS;
}

export const updateManifestData = (payload: {
  url: string;
  name: string;
}): UpdateManifestRequest => ({
  type: UPDATE_MANIFEST_REQUEST,
  payload: payload,
});

export const updateManifestSuccess = (): UpdateManifestActionSuccess => ({
  type: UPDATE_MANIFEST_SUCCESS,
});

export type ManifestAction =
  | UpdateManifestActionSuccess
  | UpdateManifestRequest;
