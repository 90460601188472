import {
  GET_IFRAME_ERROR,
  GET_IFRAME_REQUEST,
  GET_IFRAME_SUCCESS,
} from "../constans/iframe.constants";
import { IframeAction } from "../actions/iframe.actions";

type IframeState = {
  iframe: { url: string; name: string } | null;
  loading: boolean;
  error: Error | null;
};

const initialState: IframeState = {
  iframe: null,
  loading: true,
  error: null,
};

const IframeReducer = (
  state = initialState,
  action: IframeAction
): IframeState => {
  switch (action.type) {
    case GET_IFRAME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_IFRAME_SUCCESS:
      return {
        ...state,
        iframe: action.payload,
        loading: false,
      };
    case GET_IFRAME_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default IframeReducer;
