import { RouteProps } from "react-router";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getPinData } from "../store/actions/pin.action";

const PrivateRoute: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const [pin] = useSearchParams();
  const pinData = pin.get("pin");

  useEffect(() => {
    if (pinData) {
      dispatch(getPinData(pinData));
    }
  }, []);

  const { component } = props;

  return component;
};

export default PrivateRoute;
